@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.loader {
  &__block {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    background: #fffc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__element {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: linear-gradient(#fff, #fff), linear-gradient(15deg, #a70, #06d);
    background-clip: content-box, border-box;
    padding: 3px;
    animation: spin 1s infinite linear;
    transform-origin: center;
  }
}
